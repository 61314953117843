import React from "react";
import { Map, CircleMarker, Popup, TileLayer } from "react-leaflet";
import { Button, Panel, Glyphicon } from "react-bootstrap";
import { getUserInfo } from "../lib/auth0";
import ACFConstituent from "./ACFconstituent.js";
import CPConstituent from "./CPconstituent.js";
import CPUConstituent from "./CPUconstituent.js";
import WVConstituent from "./WVconstituent.js";
import Universalconstituent from "./Universalconstituent";
import Select from "react-select";
import { serverFetch } from "../lib/server";
import ErrorModal from "../components/errorModal.js";

export default class MapPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: props.match.params.id,
      missing: 0,
      pins: [],
      loading: false,
      bounds: [[-10, -200], [10, 200]],
      userName: "",
      show: false,
      constituent: "",
      banner: "",
      orgName: this.props.location.state.orgName,
      showMulti: false,
      options: [
        { value: "Upgrade", label: "Upgrade" },
        { value: "Lapsed", label: "Lapsed" },
        { value: "Reactivation", label: "Reactivation" },
        { value: "Telethon Lapsed", label: "Telethon Lapsed" },
        { value: "Telethon", label: "Telethon" },
        { value: "Lottery", label: "Lottery" },
        { value: "Completed", label: "Completed" },
        { value: "OTG Conversion", label: "OTG Conversion" },
        { value: "Petition Conversion", label: "Petition Conversion" }
      ],
      selectedOption: [
        { value: "Upgrade", label: "Upgrade" },
        { value: "Lapsed", label: "Lapsed" },
        { value: "Reactivation", label: "Reactivation" },
        { value: "Telethon Lapsed", label: "Telethon Lapsed" },
        { value: "Telethon", label: "Telethon" },
        { value: "Lottery", label: "Lottery" },
        { value: "OTG Conversion", label: "OTG Conversion" },
        { value: "Petition Conversion", label: "Petition Conversion" }
      ]
    };
    const userInfo = getUserInfo();
    this.state.userName = userInfo.name;

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.updatePin = this.updatePin.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleShowMulti = this.handleShowMulti.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    this.loadConstituents();

    serverFetch("/api/v1/campaigns/" + this.state.campaign + "/banner")
      .then(json => {
        this.setState({ banner: json.bannerBlob });
      })
      .catch(e => {
        this.setState({ error: e });
      });
  }

  loadConstituents() {
    this.setState({
      loading: true
    });

    serverFetch("/api/v1/campaigns/" + this.state.campaign + "/map")
      .then(json => {
        this.setState(
          {
            missing: json.Missing,
            pins: Array.from(json.Pins),
            loading: false
          },
          this.setBounds
        );
      })
      .catch(e => {
        this.setState({
          loading: false,
          error: e
        });
      });
  }

  setBounds() {
    if (this.state.pins.length > 0) {
      const lats = this.state.pins.map(p => p.Latitude);
      const longs = this.state.pins.map(p => p.Longitude);
      const maxLat = Math.max(...lats);
      const minLat = Math.min(...lats);
      const maxLong = Math.max(...longs);
      const minLong = Math.min(...longs);
      this.setState({
        bounds: [[minLat, minLong], [maxLat, maxLong]]
      });
    }
  }

  // Takes the list of pins and returns the data in the form:
  // pinsByLocation["-50.3456,10.2988"] = [pin, pin, pin...]
  groupPinsByLocation() {
    const pins = [...this.state.pins];
    const pinsByLocation = {};
    for (let i = 0; i < pins.length; i++) {
      let loc = pins[i].Latitude + "," + pins[i].Longitude;
      if (pinsByLocation[loc] === undefined) {
        pinsByLocation[loc] = [pins[i]];
      } else {
        pinsByLocation[loc].push(pins[i]);
      }
    }
    return pinsByLocation;
  }

  // After modifying a constituent with a PUT request, updatePin incorporates the updated
  // constituent back into our list of pins, thus updating its representation on the map.
  updatePin(constituent) {
    const newPin = {
      ID: constituent.ID,
      DonorType: constituent.DonorType,
      Salutation: constituent.Salutation,
      AddressLine1: constituent.AddressLine1,
      AddressLine2: constituent.AddressLine2,
      Latitude: constituent.Latitude,
      Longitude: constituent.Longitude,
      PinColor: constituent.PinColor,
      Completed: constituent.Complete
    };

    // Make a copy of the pins
    var pins = [...this.state.pins];

    // Find the pin with the correct ID, and replace it with newPin
    pins.find((o, i) => {
      if (o.ID === newPin.ID) {
        pins[i] = newPin;
        return true; // stop searching
      }
      return false;
    });

    // Write the new copy of pins back to state
    this.setState({
      pins: pins
    });
  }

  checkToRender(pin) {
    const { selectedOption } = this.state;
    let toRender = false;
    let renderCompleted = false;
    for (let i = 0; i < selectedOption.length; i++) {
      if (selectedOption[i].value === "Completed") {
        renderCompleted = true;
      }
      if (pin.DonorType === selectedOption[i].value) {
        toRender = true;
      }
    }
    if (!toRender || (!renderCompleted && pin.Completed)) {
      return false;
    }
    return true;
  }

  renderPopUp(location) {
    return location.map(pin => {
      if (!this.checkToRender(pin)) {
        return null;
      }
      return (
        <div key={pin.ID}>
          <Button
            bsStyle="link"
            className="con-btn-link"
            onClick={() => this.handleClick(pin)}
          >
            {pin.Salutation}
          </Button>
          <br />
          {pin.AddressLine1}
          <br />
          <br />
        </div>
      );
    });
  }

  renderMarkers() {
    let pinsToRender = this.groupPinsByLocation();

    return Object.keys(pinsToRender).map(location => {
      return pinsToRender[location].map(pin => {
        if (!this.checkToRender(pin)) {
          return null;
        }
        const position = [];
        let fill = 0;

        if (
          pin.DonorType.toLowerCase() === "reactivation" ||
          pin.DonorType.toLowerCase() === "petition conversion"
        ) {
          fill = 0.5;
        }

        if (
          pin.DonorType.toLowerCase() === "lapsed" ||
          pin.DonorType.toLowerCase() === "telethon" ||
          pin.DonorType.toLowerCase() === "lottery"
        ) {
          fill = 1;
        }

        position.push(pin.Latitude);
        position.push(pin.Longitude);

        return (
          <CircleMarker
            key={pin.ID}
            center={position}
            color={this.renderColor(pinsToRender[location])}
            fillOpacity={fill}
          >
            <Popup>{this.renderPopUp(pinsToRender[location])}</Popup>
          </CircleMarker>
        );
      });
    });
  }

  //When multiple constituents share the same location and each have different colours, this function chooses the most appropriate colour to represent the group
  renderColor(pins) {
    for (let i = 0; i < pins.length; i++) {
      //"if any pin is blue, then make the entire group blue"
      if (pins[i].PinColor === "#2F41D5") {
        return "#2F41D5";
      }
    }
    //"otherwise, just use the colour of the first pin in the group"
    return pins[0].PinColor;
  }

  handleClick(p) {
    this.setState({
      constituent: p.ID,
      show: true
    });
  }

  handleClose() {
    this.setState({
      show: false
    });
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  // called from child components to indicate an error on this page
  handleError(error) {
    this.setState({
      error: error
    });
  }

  handleShowMulti() {
    this.setState({
      showMulti: !this.state.showMulti
    });
  }

  renderMultiSelect() {
    const { selectedOption, options } = this.state;
    return (
      <div>
        <Button
          className="multi-btn"
          onClick={() => this.handleShowMulti()}
          bsSize="small"
        >
          <Glyphicon glyph="menu-right" />
        </Button>
        <Select
          defaultValue={selectedOption}
          isMulti
          onChange={this.handleChange}
          options={options}
          className="basic-multi-select"
        />
      </div>
    );
  }

  render() {
    return (
      <div className="map-container">
        <ErrorModal
          error={this.state.error}
          onDismiss={() => {
            this.setState({ error: undefined });
          }}
        />
        {this.state.showMulti ? (
          this.renderMultiSelect()
        ) : (
          <Button
            className="multi-btn"
            onClick={() => this.handleShowMulti()}
            bsSize="small"
          >
            <Glyphicon glyph="menu-left" />
          </Button>
        )}
        {this.state.show && this.state.orgName === "Universal" ? (
          <div id="conPage">
            <Universalconstituent
              campaign={this.state.campaign}
              constituent={this.state.constituent}
              close={this.handleClose}
              onSave={this.updatePin}
              banner={this.state.banner}
              onError={this.handleError}
            />
          </div>
        ) : null}
        {this.state.show && this.state.orgName === "ACF" ? (
          <div id="conPage">
            <ACFConstituent
              campaign={this.state.campaign}
              constituent={this.state.constituent}
              close={this.handleClose}
              onSave={this.updatePin}
              banner={this.state.banner}
              onError={this.handleError}
            />
          </div>
        ) : null}
        {this.state.show && this.state.orgName === "Canuck Place" ? (
          <div id="conPage">
            <CPConstituent
              campaign={this.state.campaign}
              constituent={this.state.constituent}
              close={this.handleClose}
              onSave={this.updatePin}
              banner={this.state.banner}
              onError={this.handleError}
            />
          </div>
        ) : null}
        {this.state.show && this.state.orgName === "Canuck Place Upgrade" ? (
          <div id="conPage">
            <CPUConstituent
              campaign={this.state.campaign}
              constituent={this.state.constituent}
              close={this.handleClose}
              onSave={this.updatePin}
              banner={this.state.banner}
              onError={this.handleError}
            />
          </div>
        ) : null}
        {this.state.show && this.state.orgName === "World Vision" ? (
          <div id="conPage">
            <WVConstituent
              campaign={this.state.campaign}
              constituent={this.state.constituent}
              close={this.handleClose}
              onSave={this.updatePin}
              banner={this.state.banner}
              onError={this.handleError}
            />
          </div>
        ) : null}
        <Map bounds={this.state.bounds}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            minZoom={2}
          />
          {this.renderMarkers()}
        </Map>
        {this.state.loading ? (
          <Panel bsClass="panel map-panel-overlay">Loading...</Panel>
        ) : null}
        {this.state.missing > 0 ? (
          <Panel bsClass="panel map-panel-overlay">
            {this.state.missing} constituents are waiting to be geocoded and are
            not displayed.
          </Panel>
        ) : null}
      </div>
    );
  }
}
